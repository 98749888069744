import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ReportsList from 'features/Remittance/ReportsList';
import './RemittanceContent.scss';

const ReportContent = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: '11.3rem',
      },
      list: {
        fontSize: '16px',
        padding: '0 16px',
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100%)',
      },
      contentClass: {
        margin: '0 auto auto',
      },
    }),
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <ReportsList contentClassName={classes.contentClass} />
      </div>
    </div>
  );
};

export default ReportContent;
