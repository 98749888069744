import {
  categorizeOrderInsightRisks,
  getParentSeverity,
  PropertyOrderInsightsProps,
  RiskCategory,
} from 'api/file/interfaces/get-file-order-insights';
import React from 'react';
import OrderInsightsRiskIcon from './OrderInsightsRiskIcon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import OrderInsightRisks from './OrderInsightRisks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flex: '1',
      paddingLeft: theme.spacing(1),
    },
    brdBtm: {
      borderBottom: '1px solid #E9E9E9',
      padding: theme.spacing(2, 0),
      pageBreakInside: 'avoid',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    labelCol: {
      minWidth: '25%',
      maxWidth: '25%',
      marginRight: theme.spacing(3),
    },
    flexGrow: {
      flex: '1',
    },
    ml1: {
      marginLeft: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    minWidth: {
      marginRight: theme.spacing(1),
      minWidth: '100px',
      display: 'inline-block',
      fontWeight: 500,
    },
    danger: {
      background: '#FDECEA',
      color: '#B3150A',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      '& a': {
        color: '#B3150A',
      },
    },
    warning: {
      background: '#FDF4E7',
      color: '#C77700',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      '& a': {
        color: '#C77700',
      },
    },
    success: {
      background: '#EFF7EE',
      color: '#3B873E',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    f8: {
      fontSize: '9pt',
    },
    f14: {
      fontSize: '14px',
    },
  }),
);

const EstMarketValue: React.FC<PropertyOrderInsightsProps> = ({ property, isPdf }) => {
  const { alertRisks, warningRisks, unknownRisks } = categorizeOrderInsightRisks(
    property.risks,
    RiskCategory.MarketValue,
  );

  const classes = useStyles();

  return (
    <div className={classes.brdBtm}>
      <div className={classes.flex}>
        <div className={classes.labelCol}>
          <span className={classes.flexCenter}>
            <OrderInsightsRiskIcon
              isPdf={isPdf}
              riskSeverity={getParentSeverity(property.risks, RiskCategory.MarketValue)}
            />
            <Typography
              variant="h4"
              color="textSecondary"
              className={clsx(classes.ml1, isPdf ? classes.f8 : classes.f14)}
            >
              Est. Market Value:
            </Typography>
          </span>
        </div>
        <div className={classes.flexGrow}>
          {(property.taxInformation?.marketValue ?? -1) >= 0 && (
            <Typography variant="body1" className={clsx(classes.mb1, isPdf ? classes.f8 : classes.f14)}>
              {Number(property.taxInformation?.marketValue).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          )}
          <OrderInsightRisks isPdf={isPdf} alerts={alertRisks} warnings={warningRisks} unknowns={unknownRisks} />
        </div>
      </div>
    </div>
  );
};

export default EstMarketValue;
