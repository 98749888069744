import React from 'react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Help from '@material-ui/icons/Help';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

// Define the prop type
type OrderInsightsRiskIconProps = {
  riskSeverity?: string;
  isPdf?: boolean;
};
const useStyles = makeStyles(() =>
  createStyles({
    f18: {
      fontSize: '18px',
    },
    f13: {
      fontSize: '13px',
    },
    green: {
      color: '#3B873E',
    },
    orange: {
      color: '#C77700',
    },
    red: {
      color: '#B3150A',
    },
    grey: {
      color: '#757575',
    },
    center: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const OrderInsightsRiskIcon: React.FC<OrderInsightsRiskIconProps> = ({ riskSeverity, isPdf }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {isPdf ? (
        <div>
          {riskSeverity === 'Success' && <VerifiedUserIcon className={clsx(classes.f13, classes.green)} />}
          {riskSeverity === 'Alert' && <ErrorIcon className={clsx(classes.f13, classes.red)} />}
          {riskSeverity === 'Warning' && <ReportProblemIcon className={clsx(classes.f13, classes.orange)} />}
          {riskSeverity === 'None' && <Help className={clsx(classes.f13, classes.grey)} />}
        </div>
      ) : (
        <div className={clsx(classes.center, classes.f13)}>
          {riskSeverity === 'Success' && <VerifiedUserOutlinedIcon className={clsx(classes.f18, classes.green)} />}
          {riskSeverity === 'Alert' && (
            <ErrorOutlineOutlinedIcon htmlColor="#B3150A" className={classes.f18}></ErrorOutlineOutlinedIcon>
          )}
          {riskSeverity === 'Warning' && <ReportProblemOutlinedIcon className={clsx(classes.f18, classes.orange)} />}
          {riskSeverity === 'None' && <HelpOutlineOutlinedIcon className={clsx(classes.f18, classes.grey)} />}
        </div>
      )}
    </React.Fragment>
  );
};

export default OrderInsightsRiskIcon;
