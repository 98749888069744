import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { RateFeeLineItem } from 'api/file/interfaces/get-file-summary-report';

type RateFeeLineItemInfoProps = {
  rateFeeLineItems: RateFeeLineItem[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    fixedWidth: {
      width: '300px',
    },
    f8: {
      fontSize: '9pt',
      padding: theme.spacing(0, 1),
      textAlign: 'right',
    },
  }),
);

const RateFeeLineItemInfo: React.FC<RateFeeLineItemInfoProps> = ({ rateFeeLineItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.fullWidth}>
      <Grid container spacing={1}>
        {rateFeeLineItems.map((lineItem: RateFeeLineItem, index: number) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              {(lineItem.grossPremium ?? -1) >= 0 && (
                <Typography className={classes.f8}>
                  {Number(lineItem.grossPremium).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              {(lineItem.netPremium ?? -1) >= 0 && (
                <Typography className={classes.f8}>
                  {Number(lineItem.netPremium).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              )}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
};

export default RateFeeLineItemInfo;
