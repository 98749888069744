import { PropertyOrderInsightsProps } from 'api/file/interfaces/get-file-order-insights';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AssessedValue from './components/AssessedValue';
import EstMarketValue from './components/EstMarketValue';
import FinCen from './components/FinCen';
import Occupancy from './components/Occupancy';
import OwnerName from './components/OwnerName';
import OwnersMailingAddress from './components/OwnersMailingAddress';
import RecordedPropertyInfo from './components/RecordedPropertyInfo';
import OrderInsightsRiskIcon from './components/OrderInsightsRiskIcon';
import EstMortgageBalance from './components/EstMortgageBalance';
import PolicyLiability from './components/PolicyLiability';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    w100: {
      width: '100%',
    },
    danger: {
      background: '#FDECEA',
      color: '#5F2120',
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '9pt',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      margin: theme.spacing(1, 0),
      '& a': {
        color: '#5F2120',
        '&:hover': {
          opacity: '0.8',
        },
      },
    },
  }),
);

const PropertyOrderInsights: React.FC<PropertyOrderInsightsProps> = ({
  property,
  policyLiabilityJacket,
  isPdf,
  index,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.w100}>
      {property.risks?.some((x) => x.categoryName === 'Property' && x.typeName === 'MissingData') && (
        <div className={classes.danger}>
          <OrderInsightsRiskIcon isPdf={isPdf} riskSeverity="Alert" />
          <span>Unable to validate Order Insights for this property.</span>
        </div>
      )}

      {property.propertyDetailId && (
        <>
          <RecordedPropertyInfo property={property} isPdf={isPdf} />
          <OwnerName property={property} isPdf={isPdf} />
          <OwnersMailingAddress property={property} isPdf={isPdf} />
          <Occupancy property={property} isPdf={isPdf} />
          <EstMarketValue property={property} isPdf={isPdf} />
          <EstMortgageBalance property={property} isPdf={isPdf} />
          <AssessedValue property={property} isPdf={isPdf} />
          <FinCen property={property} isPdf={isPdf} />
          {index === 0 && policyLiabilityJacket && (
            <PolicyLiability property={property} policyLiabilityJacket={policyLiabilityJacket} isPdf={isPdf} />
          )}
        </>
      )}
    </div>
  );
};

export default PropertyOrderInsights;
