import React from 'react';
import { Cpl } from 'api/file/interfaces/get-file-summary-report';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

type CplInfoProps = {
  cpls: Cpl[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
      boxShadow: 'none',
    },
    tableHead: {
      padding: theme.spacing(1, 1, 0, 1),
      fontSize: '11pt',
      fontWeight: 800,
      border: 'none',
    },
    tableCell: {
      padding: theme.spacing(1, 1, 0, 1),
      fontSize: '10pt',
    },
  }),
);

const CplInfo: React.FC<CplInfoProps> = ({ cpls }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>Letter Type</TableCell>
            <TableCell className={classes.tableHead} align="left">
              Covered Party
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Status
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Lender
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Created Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cpls.map((row: Cpl, index: number) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell} component="th" scope="row">
                {row.letterTypeName}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.coveredPartyDesc}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.cplStatus}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.lenderName}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.createdDate}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CplInfo;
