import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ServiceOrder } from 'api/file/interfaces/get-file-summary-report';

type ServiceOrderInfoProps = {
  serviceOrder: ServiceOrder;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    fixedWidth: {
      width: '300px',
    },
    f8: {
      fontSize: '9pt',
      paddingLeft: theme.spacing(1),
    },
  }),
);

const ServiceOrderInfo: React.FC<ServiceOrderInfoProps> = ({ serviceOrder }) => {
  const classes = useStyles();

  return (
    <div className={classes.fullWidth}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography className={classes.f8}>Order No:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{serviceOrder.orderNumber}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>Sale Price:</Typography>
        </Grid>
        <Grid item xs={3}>
          {(serviceOrder.salePriceAmount ?? -1) >= 0 && (
            <Typography className={classes.f8}>
              {Number(serviceOrder.salePriceAmount).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>Status:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{serviceOrder.orderStatus}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>Status Date:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{serviceOrder.modifiedDate}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>Service Order Contact:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{serviceOrder.officeName}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>Contact Phone:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.f8}>{serviceOrder.orderReferenceId}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceOrderInfo;
