import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ITooltipParams, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import { dateTooltipValue, openDocument, errorTooltipValue } from 'utilities/utilities';
import { searchReports, exportReports, addRemoveQueue, ReportFiles, getPayOrdersWithRange } from 'api/reports/api';
import {
  ErrorRenderer,
  autoReportingInfoText,
  cplFilters,
  filterParams,
  formatDate,
  noFilesText,
  numberValueFormatter,
  queueFilters,
  restrictReportingText,
  reportQueueButtonAttributes,
  searchFilesReportedText,
  restrictLimitReportingText,
  orderStatuses,
  report,
  pay,
  review,
} from './reportsListConfig';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import AgentNetDropdownSelector from 'ui-kit/inputs/AgentNetDropdownSelector';
import { ButtonGroup, Grid, Typography } from '@material-ui/core';
import PolicyDateRenderer from './PolicyDateRenderer';
import CalculatorRenderer from './CalculatorRenderer';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import { WindowFeatures, newWindow, excelDocType, DocumentType } from 'features/constants';
import ReportsAndPayDrawer from 'core/layout-core/core-content/ReportsAndPayDrawer';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import NoResults from 'ui-kit/components/noResults/NoResults';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import '../files/files-summary/files-list/FilesList.scss';
import './Remittance.scss';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import {
  StatusRenderer,
  FileNumber as FileNumberRenderer,
} from 'features/files/files-summary/files-list/filesListConfigs';
import AutoReportingIcon from 'ui-kit/icons/AutoReportingIcon';
import InfoIcon from 'ui-kit/components/InfoIcon/InfoIcon';
import ManageSearchIcon from 'ui-kit/icons/ManageSearchIcon';
import PayDrawer from 'core/layout-core/core-content/PayDrawer';
import useRatesAndFee from './useRatesAndFee';
import InvoiceSummary from './InvoiceSummary';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import SearchField from 'features/files/files-summary/search-form/SearchField';
import { FieldValidationError, doValidate } from 'utilities/validation/validation';
import { searchFirmSchema } from 'utilities/validation/schemas/on-behalf-schema';
import { getTransmittalSheet } from 'api/payment/api';
import ReopenedFileIcon from './ReopenedFileIcon';
import PdfCellRenderer from './PdfCellRenderer';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { Pay_Manual_Only, Pay_Rights, View_Pay } from 'utilities/constants/activity-rights';

export interface ReportsListInterface {
  contentClassName?: string;
}

type RouteParams = {
  action: string;
  firmId: string;
};

const ReportsList = ({ contentClassName }: ReportsListInterface) => {
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const history = useHistory();
  const location = useLocation();
  const { action, firmId } = useParams<RouteParams>();
  const tabName = action?.charAt(0).toUpperCase() + action?.slice(1);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      tableDefault: {
        height: `calc(100vh - ${selectedRows?.length ? '400px' : '340px'}) !important`,
        fontFamily: theme.typography.fontFamily,
        marginTop: 24,
      },
      selectedRows: {
        paddingBottom: 12,
      },
      selectedRowsActive: {
        marginBottom: '72px',
        paddingBottom: 0,
      },
      mt0: {
        marginTop: 0,
      },
      mb0: {
        marginBottom: 0,
      },
    }),
  );
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = profileCtx;
  const { addSnackbarMessage } = useSnackBars();
  const gridRef = useRef<any>(null);
  const gridReadyTimeout = useRef<any>(null);

  const [rowData, setRowData] = useState<any>([]);
  const queueFilesCount: any = {
    eligibleFiles: rowData?.filter((item: any) => !item.isMyQueue && !item.isFirmQueue).length,
    myQueueOnlyFiles: rowData?.filter((item: any) => item.isMyQueue).length,
    firmQueueOnlyFiles: rowData?.filter((item: any) => item.isFirmQueue).length,
  };
  const keyToSum = tabName === report ? 'net' : tabName === pay ? 'amountDue' : '';
  const sumWithReduce = selectedRows?.reduce(
    (accumulator: any, obj: { [x: string]: any }) => accumulator + obj[keyToSum],
    0,
  );
  const columnToHide = 'underwriter';
  const isNYStateFile = selectedRows?.some((row: any) => row['propertyState'] === 'NY');
  const isAllunderwritersSame = selectedRows?.every(
    (row: any) => row[columnToHide] === selectedRows[0]?.[columnToHide],
  );
  const isAllFirmsSame = selectedRows?.every((row: any) => row['firmId'] === selectedRows[0]?.['firmId']);
  const isFilesReportable = isAllunderwritersSame && isAllFirmsSame;
  const [isAllOpenStatus, setIsAllOpenStatus] = useState<boolean>(true);
  const displayFileReopenedIcon = rowData?.length > 0 && rowData.some((row: any) => row['isReopened']);
  const isReopened = selectedRows?.length > 0 && selectedRows.some((row: any) => row['isReopened']);
  const [showOnlyOpenStatusMsg, setShowOnlyOpenStatusMsg] = useState<boolean>(false);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);
  const [reportFirmId, setReportFirmId] = useState<string | null>(null);
  const [reportFileOrderIds, setReportFileOrderIds] = useState<number[]>([]);
  const [payGridReady, setPayGridReady] = useState(false);

  const filterModel = useRef({});
  const sortModel = useRef({});
  const [notifyEmail, setNotifyEmail] = useState<string>(profile?.emailAddress ?? '');
  const exportedFiles = { files: selectedRows?.map((data: any) => data?.fileId), email: notifyEmail };
  const reportAndPayLimit = 49;
  const exportedOrders = {
    orders: selectedRows?.map((order: any) => {
      return { orderNumber: order?.orderNumber, invoiceType: order?.invoiceType ?? 'Remittance' };
    }),
    firmId: reportFirmId ?? userFirm?.firmId,
  };
  const payFiles: any = {
    firmId: reportFirmId ?? userFirm?.firmId,
    action: isAllOpenStatus ? 'Pay' : 'View',
  };

  if (selectedRows && selectedRows.length > 0) {
    const orders = selectedRows
      .map((order: any) => {
        return { orderNumber: order?.orderNumber, invoiceType: order?.invoiceType ?? 'Remittance' };
      })
      .filter((order: any) => order?.orderNumber != null);
    const fileOrderIds = Array.from(
      new Set(
        selectedRows
          .map((data: any) => data?.fileOrderIds)
          .flat()
          .filter((fileOrderId: any) => fileOrderId),
      ),
    );

    if (orders?.length > 0) {
      payFiles.orders = orders;
    }
    if (fileOrderIds?.length > 0) {
      payFiles.fileOrderIds = fileOrderIds;
    } else if (reportFileOrderIds?.length > 0) {
      payFiles.fileOrderIds = reportFileOrderIds;
    }
  }
  const [openQueueModal, setOpenQueueModal] = useState<boolean>(false);
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  const [openUnableToProcessModal, setOpenUnableToProcessModal] = useState<boolean>(false);
  const [openInvoiceSummary, setOpenInvoiceSummary] = useState<boolean>(false);
  const [reportCLicked, setReportCLicked] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [resetData, setReset] = useState<boolean>(false);
  const [showGridLoader, setGridLoader] = useState<boolean>(false);

  const [errMsgs, setErrMsgs] = useState([]);
  const [cplFilter, setCplFilter] = useState<string>('excludeCplOnlyFiles');
  const [queueFilter, setQueueFilter] = useState<string>('eligibleFiles');
  const totalFileCount = tabName === report ? queueFilesCount[queueFilter] : rowData?.length;
  let externalCplFilter = 'excludeCplOnlyFiles';
  let externalQueueFilter = 'eligibleFiles';

  const [searchText, setSearchText] = useState('');
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [showValidationError, setShowValidationError] = useState<boolean>(false);

  const today = moment().format('YYYY-MM-DD');
  const ninetyDaysAgo = moment().subtract(90, 'days').format('YYYY-MM-DD');
  const [dateRange, setDateRange] = useState<{ fromDate: string; toDate: string }>({
    fromDate: ninetyDaysAgo,
    toDate: today,
  });
  const [paymentId, setPaymentId] = useState<number>(0);
  const [orderNumber, setOrderNumber] = useState<number>(0);

  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    userFirm?.firmId ?? '',
    tabName,
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, [tabName]);

  const getReportData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await searchReports(token, userFirm?.firmId ?? '', tabName);
  };
  const {
    execute,
    status: reportListStatus,
    value: reportResults,
    errors: listErrors,
  } = useAsync<any>(getReportData, false);

  const getPayOrders = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const payload = {
      firmId: userFirm?.firmId,
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
    };
    return await getPayOrdersWithRange(token, payload);
  };
  const {
    execute: executePayOrdersWithRange,
    status: payOrdersWithRangeStatus,
    value: payOrdersWithRangeResults,
    errors: payOrdersWithRangeErrors,
  } = useAsync<any>(getPayOrders, false);

  const getExportData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await exportReports(tabName === pay ? exportedOrders : exportedFiles, token, tabName);
  };
  const {
    execute: executeExportData,
    status: executeExportDataStatus,
    value: exportResults,
    errors: exportErrors,
  } = useAsync<any>(getExportData, false);

  const getQueueData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const type = queueFilter === 'eligibleFiles' ? 'add' : 'remove';
    return await addRemoveQueue(token, exportedFiles, type);
  };
  const {
    execute: executeQueueData,
    status: executeQueueDataStatus,
    value: queueResults,
    errors: queueErrors,
  } = useAsync<any>(getQueueData, false);

  const reportSelectedFiles = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await ReportFiles(exportedFiles, token, tabName);
  };
  const {
    execute: executeReportData,
    status: executeReportDataStatus,
    value: reportedFiles,
    errors: ReportErrors,
  } = useAsync<any>(reportSelectedFiles, false);

  const getTransmittalSheetDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getTransmittalSheet(token, paymentId);
  };
  const {
    execute: executeTransmittalSheet,
    status: executeTransmittalSheetStatus,
    value: transmittalSheetResults,
    errors: transmittalSheetErrors,
  } = useAsync<any>(getTransmittalSheetDetails, false);

  const getUpdatedData = () => {
    if (!resetData && userFirm?.firmId) execute().then();
  };

  useEffect(() => {
    if (firmId !== 'invoice-summary') {
      const openStatus =
        tabName !== pay ||
        !selectedRows?.length ||
        selectedRows.every(
          (row: any) =>
            row['orderStatus'] === orderStatuses['open'] ||
            row['orderStatus'] === orderStatuses['manualPay'] ||
            row['orderStatus'] === orderStatuses['wirePay'],
        ) ||
        selectedRows?.[0]?.getPaymentData;
      setIsAllOpenStatus(openStatus);
    }
  }, [selectedRows, firmId]);

  useEffect(() => {
    resetFiltersAndSelection();
    tabName === pay ? executePayOrdersWithRange().then() : getUpdatedData();
  }, [tabName]);

  useEffect(() => {
    setNotifyEmail(profile?.emailAddress ?? '');
  }, [profile?.emailAddress]);

  useEffect(() => {
    if (tabName === pay) {
      gridRef.current?.api.hidePopupMenu();
      executePayOrdersWithRange().then();
    }
  }, [dateRange.fromDate, dateRange.toDate]);

  useEffect(() => {
    if (selectedRows?.[0]?.initiateExport) {
      executeExportData().then();
      setSelectedRows([]);
    }
  }, [selectedRows?.[0]?.initiateExport]);

  useEffect(() => {
    if (selectedRows?.[0]?.initiateInvoiceAndPay) {
      history.push({
        pathname: `/accounting/remittance/pay/invoice-summary`,
        state: {
          selectedRows,
        },
      });
    }
  }, [selectedRows?.[0]?.initiateInvoiceAndPay]);

  useEffect(() => {
    const currentState: any = location?.state;
    const isInvoiceSummary = location.pathname.includes('/invoice-summary');
    if (currentState?.selectedRows) {
      switchToSummaryPage(
        currentState?.selectedRows,
        true,
        currentState?.localFirmID,
        currentState?.reportedFileOrderids,
      );
    } else if (isInvoiceSummary) {
      history.push('/accounting/remittance/pay');
    }
  }, [location?.state]);

  useEffect(() => {
    if (showOnlyOpenStatusMsg) {
      addSnackbarMessage({
        message: 'Only Open and pending payment orders with same underwriter can be submitted for payment',
        type: 'warning',
        onClose: () => setShowOnlyOpenStatusMsg(false),
      });
    }
  }, [showOnlyOpenStatusMsg]);

  const switchToSummaryPage = (
    currentSelectedRows: any,
    showSummary: boolean,
    localFirmID?: string | null,
    reportedFileOrderids?: number[],
  ) => {
    if (localFirmID) {
      setReportFirmId(localFirmID);
    }
    if (reportedFileOrderids) {
      setReportFileOrderIds(reportedFileOrderids);
    }
    // handleTabChange?.(false, 2);
    setSelectedRows(currentSelectedRows);
    setOpenInvoiceSummary(showSummary);
    setReset(true);
    const resetTimer = setTimeout(() => {
      // immediately reset back to false to allow to navigate to different route
      setReset(false);
      clearTimeout(resetTimer);
    }, 0);
    setRowData([]);
  };

  useEffect(() => {
    if (executeQueueDataStatus === 'success') {
      addSnackbarMessage({
        message: `File(s) were ${queueFilter === 'eligibleFiles' ? 'queued' : 'removed from queue'}`,
        type: 'success',
      });
      resetFiltersAndSelection(queueFilter, cplFilter);
      getUpdatedData();
    }
  }, [executeQueueDataStatus]);

  useEffect(() => {
    tabName === pay ? setRowData(payOrdersWithRangeResults) : setRowData(reportResults);
  }, [reportResults, payOrdersWithRangeResults]);

  useEffect(() => {
    const hideColumnIfNeeded = () => {
      const columnApi = gridRef.current?.columnApi;

      if (columnApi && (tabName === 'Report' || tabName === 'Pay')) {
        const isAllUnderwritersSame =
          (tabName === 'Report' &&
            reportResults?.every((row: any) => row[columnToHide] === reportResults?.[0]?.[columnToHide])) ||
          (tabName === 'Pay' &&
            payOrdersWithRangeResults?.every(
              (row: any) => row[columnToHide] === payOrdersWithRangeResults?.[0]?.[columnToHide],
            ));

        columnApi.setColumnVisible(columnToHide, !isAllUnderwritersSame);
      }
    };

    if (gridRef.current?.api && (Array.isArray(reportResults) || Array.isArray(payOrdersWithRangeResults))) {
      setDisplayedRowCount(gridRef.current.api.getDisplayedRowCount());
      hideColumnIfNeeded();
    }

    if (gridRef?.current?.api?.getDisplayedRowCount() === 0) {
      gridRef.current?.api.showNoRowsOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [gridRef.current?.api, tabName, columnToHide, reportResults, payOrdersWithRangeResults]);

  useEffect(() => {
    if (executeExportDataStatus === 'success') {
      if (exportResults?.excelData) {
        openDocument(exportResults?.excelData, excelDocType, newWindow, WindowFeatures);
      }
    }
  }, [executeExportDataStatus]);

  useEffect(() => {
    if (executeQueueDataStatus === 'success') setOpenQueueModal(false);
  }, [executeQueueDataStatus]);

  const hasViewPayRight = useMemo(() => {
    return Boolean(
      profile?.activityRights?.find(
        (rights) => rights.ActivityRightId === Pay_Rights || rights.ActivityRightId === View_Pay,
      ),
    );
  }, [profile?.activityRights]);

  const hasPayRight = useMemo(() => {
    return Boolean(
      profile?.activityRights?.find(
        (rights) => rights.ActivityRightId === Pay_Rights || rights.ActivityRightId === Pay_Manual_Only,
      ),
    );
  }, [profile?.activityRights]);

  useEffect(() => {
    if (executeReportDataStatus === 'success') {
      setReportFileOrderIds(reportedFiles?.fileOrderIds);
      setOpenReportModal(false);
      let localFirmID;
      if (selectedRows[0]?.['firmId']?.toString() !== userFirm?.firmId) {
        localFirmID = selectedRows[0]?.['firmId']?.toString();
        setReportFirmId(localFirmID);
      }
      if (reportCLicked) {
        setReportCLicked(false);
        if (hasViewPayRight) {
          history.push('/accounting/remittance/pay');
        } else {
          getUpdatedData();
        }
        addSnackbarMessage({
          message: `${selectedRows.length} Reporting Successful`,
          type: 'success',
        });
      } else {
        history.push({
          pathname: `/accounting/remittance/pay/invoice-summary`,
          state: {
            localFirmID,
            selectedRows,
            reportedFileOrderids: reportedFiles?.fileOrderIds,
          },
        });
      }
    }
  }, [executeReportDataStatus]);

  useEffect(() => {
    if (paymentId) executeTransmittalSheet().then();
  }, [paymentId]);

  useEffect(() => {
    if (executeTransmittalSheetStatus === 'success') {
      if (transmittalSheetResults?.transmittalSheet)
        openDocument(transmittalSheetResults?.transmittalSheet, DocumentType, newWindow, WindowFeatures);
      setPaymentId(0);
    }
  }, [executeTransmittalSheetStatus]);

  useEffect(() => {
    const isError =
      reportListStatus === 'error' ||
      executeExportDataStatus === 'error' ||
      executeQueueDataStatus === 'error' ||
      executeReportDataStatus === 'error' ||
      payOrdersWithRangeStatus === 'error' ||
      executeTransmittalSheetStatus === 'error';
    setDisplayError(isError);
  }, [
    reportListStatus,
    executeExportDataStatus,
    executeQueueDataStatus,
    executeReportDataStatus,
    payOrdersWithRangeStatus,
    executeTransmittalSheetStatus,
  ]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [
        ...(listErrors ?? []),
        ...(exportErrors ?? []),
        ...(queueErrors ?? []),
        ...(ReportErrors ?? []),
        ...(payOrdersWithRangeErrors ?? []),
        ...(transmittalSheetErrors ?? []),
      ];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  useEffect(() => {
    doValidate({ searchText, notifyEmail }, searchFirmSchema(false)).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
    });
    if (tabName === pay) gridRef?.current?.api?.onFilterChanged();
  }, [searchText, notifyEmail]);

  const resetFiltersAndSelection = (qfilter = '', cplFilter = '') => {
    if (!resetData) {
      setSelectedRows([]);
      setOpenInvoiceSummary(false);
      setReportFirmId(null);
    }
    gridRef.current?.api?.deselectAll();
    gridRef.current?.api?.setFilterModel(null);
    externalFilterChanged(cplFilter || 'excludeCplOnlyFiles');
    externalFilterChanged(qfilter || 'eligibleFiles', true);
    setReset(false);
    setSearchText('');
    setShowValidationError(false);
  };

  const hasFutureDate = (val: any) => {
    return val?.policyDate && +new Date(val.policyDate) > +new Date();
  };
  const isRowSelectable = (params: any) => {
    return !hasFutureDate(params.data);
  };

  const headerCheckboxRenderer = () => {
    return <input type="checkbox" data-qa={`${tabName}AllCheckbox`} />;
  };

  const rowCheckboxRenderer = (params: any) => {
    const isDisabled = tabName === 'Report' && hasFutureDate(params.node.data);
    return <input type="checkbox" disabled={isDisabled} data-qa={`${tabName}File${params.node.id}Checkbox`} />;
  };

  const reviewColumnsConfig: ColDef[] = [
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
      headerComponentFramework: headerCheckboxRenderer,
      cellRendererFramework: rowCheckboxRenderer,
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        return params?.data?.isReopened ? <ReopenedFileIcon iconOnly={true} /> : '';
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
    {
      headerName: 'Error Category',
      field: 'errorCategory',
      filter: 'agSetColumnFilter',
      filterParams: {
        qaAttribute: 'ReviewErrorCategory',
        disabled: true,
        loseOnApply: true,
        suppressAndOrCondition: true,
      },
      cellRenderer: ErrorRenderer,
      onCellClicked: (event: any) => {
        handleDrawerOpen(event, 'review');
      },
      tooltipValueGetter: errorTooltipValue,
    },
    {
      headerName: 'File Number',
      field: 'fileNumber',
      sortable: true,
      filter: 'agTextColumnFilter',
      tooltipField: 'fileNumber',
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: FileNumberRenderer,
      cellRendererParams: (row: ValueFormatterParams) => ({
        id: row.data.fileId,
        fileNumber: row.data.fileNumber,
        accountNumber: row.data.accountNumber,
      }),
    },
    {
      headerName: 'Office',
      field: 'officeName',
      filter: 'agTextColumnFilter',
      tooltipField: 'officeName',
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: 'Property Address',
      field: 'propertyAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'propertyAddress',
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: 'Policy Date',
      field: 'policyDate',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: PolicyDateRenderer,
      colId: 'policyDateColumn',
      sort: 'asc',
      tooltipValueGetter: dateTooltipValue,
      maxWidth: 170,
    },
    {
      headerName: 'Buyer',
      field: 'buyerName',
      filter: 'agTextColumnFilter',
      tooltipField: 'buyerName',
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: 'Created Date',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      field: 'createdDate',
      cellRenderer: ({ value }: any) => formatDate(value),
      tooltipValueGetter: dateTooltipValue,
      maxWidth: 170,
    },
    {
      field: '',
      floatingFilter: false,
      sortable: false,
      cellRenderer: CalculatorRenderer,
      cellRendererParams: { tabName: tabName },
      onCellClicked: (event: any) => {
        handleDrawerOpen(event, 'review');
      },
      maxWidth: 50,
      cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
      tooltipValueGetter: () => 'Open Rates and Fees',
      pinned: 'right',
    },
  ];

  const reportColumnsConfig: any = [
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      pinned: 'left',
      headerComponentFramework: headerCheckboxRenderer,
      cellRendererFramework: rowCheckboxRenderer,
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        return params?.data?.isReopened ? <ReopenedFileIcon iconOnly={true} /> : '';
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
    {
      headerName: 'File Number',
      field: 'fileNumber',
      filter: 'agTextColumnFilter',
      tooltipField: 'fileNumber',
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: FileNumberRenderer,
      cellRendererParams: (row: ValueFormatterParams) => ({
        id: row.data.fileId,
        fileNumber: row.data.fileNumber,
        accountNumber: row.data.accountNumber,
        firmId: row.data.firmId?.toString(),
      }),
    },
    {
      headerName: 'Office',
      field: 'officeName',
      filter: 'agTextColumnFilter',
      tooltipField: 'officeName',
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: 'Property Address',
      field: 'propertyAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'propertyAddress',
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    { headerName: 'Underwriter', field: 'underwriter', filter: 'agSetColumnFilter', tooltipField: 'underwriter' },
    {
      headerName: 'Policy Date',
      field: 'policyDate',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: PolicyDateRenderer,
      colId: 'policyDateColumn',
      sort: 'asc',
      tooltipValueGetter: (params: ITooltipParams) => {
        return hasFutureDate(params.data)
          ? 'Future dated policies cannot be Reported'
          : moment(params.value).format('MM/DD/YYYY');
      },
      maxWidth: 170,
    },
    {
      headerName: 'Gross',
      field: 'gross',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      headerName: 'Net',
      field: 'net',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      field: '',
      floatingFilter: false,
      sortable: false,
      cellRenderer: CalculatorRenderer,
      cellRendererParams: { tabName: tabName },
      onCellClicked: (event: any) => {
        handleDrawerOpen(event, 'report');
      },
      maxWidth: 50,
      cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
      tooltipValueGetter: () => 'Open Rates and Fees',
      pinned: 'right',
    },
  ];

  const payColumnsConfig: any = [
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        return params?.data?.isReopened ? (
          <ReopenedFileIcon iconOnly={true} />
        ) : params?.data?.isOrderAutoReport ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AutoReportingIcon fontSize="small" />
          </div>
        ) : (
          ''
        );
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
    {
      headerName: 'Order Number',
      field: 'orderNumber',
      tooltipField: 'orderNumber',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: 'Order Date',
      field: 'orderDate',
      filterParams: filterParams,
      cellRenderer: PolicyDateRenderer,
      sort: 'desc',
      filter: 'agDateColumnFilter',
      tooltipValueGetter: dateTooltipValue,
      minWidth: 180,
      maxWidth: 180,
    },
    {
      headerName: 'Order Status',
      field: 'orderStatus',
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultOption: 'Open',
      },
      tooltipField: 'orderStatus',
      cellRenderer: StatusRenderer,
    },
    {
      headerName: 'Invoice Type',
      field: 'invoiceType',
      tooltipField: 'invoiceType',
      filter: 'agSetColumnFilter',
    },
    { headerName: 'Underwriter', field: 'underwriter', filter: 'agSetColumnFilter', tooltipField: 'underwriter' },
    {
      headerName: 'Gross',
      field: 'gross',
      tooltipField: 'gross',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellStyle: { textAlign: 'right' },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      headerName: 'Net',
      field: 'net',
      tooltipField: 'net',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellStyle: { textAlign: 'right' },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      headerName: 'Payments/Credits',
      field: 'paymentsCredits',
      tooltipField: 'paymentsCredits',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellStyle: { textAlign: 'right' },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      headerName: 'Amount Due',
      field: 'amountDue',
      tooltipField: 'amountDue',
      // pinned: 'right',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellStyle: { textAlign: 'right' },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      maxWidth: 130,
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: PdfCellRenderer,
      cellRendererParams: {
        paymentId: paymentId,
        orderNumber: orderNumber,
        onClick: (paymentId: any, orderNumber: any) => {
          setPaymentId(paymentId);
          setOrderNumber(orderNumber);
        },
        executeTransmittalSheetStatus: executeTransmittalSheetStatus,
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        return <span style={{ position: 'relative', top: '10px' }}>{ManageSearchIcon({ fontSize: 'small' })}</span>;
      },
      onCellClicked: (event: any) => {
        handleDrawerOpen(event, 'pay');
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
  ];

  const columnDefs = useMemo(() => {
    return tabName === review ? reviewColumnsConfig : tabName === report ? reportColumnsConfig : payColumnsConfig;
  }, [tabName, executeTransmittalSheetStatus]);

  const getDisplayedRowCount = () => {
    if (gridRef.current?.api) return gridRef.current.api.getDisplayedRowCount();
    return 0;
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current?.api?.getSelectedRows();
    setSelectedRows(selectedRows);
  }, []);

  const setDatesFromFilterModel = (model: any) => {
    if (model.type === 'equals') {
      setDateRange({
        fromDate: moment(model.dateFrom).format('YYYY-MM-DD'),
        toDate: moment(model.dateFrom).format('YYYY-MM-DD'),
      });
    } else if (model.type === 'lessThan') {
      setDateRange({
        fromDate: '',
        toDate: moment(model.dateFrom).format('YYYY-MM-DD'),
      });
    } else if (model.type === 'greaterThan') {
      setDateRange({
        toDate: '',
        fromDate: moment(model.dateFrom).format('YYYY-MM-DD'),
      });
    } else if (model.type === 'inRange') {
      setDateRange({
        fromDate: moment(model.dateFrom).format('YYYY-MM-DD'),
        toDate: moment(model.dateTo).format('YYYY-MM-DD'),
      });
    }
  };

  const onFilterChanged = () => {
    const filterModel = gridRef.current?.api.getFilterModel() ?? {};
    if (filterModel.errorCategory) {
      let errorCode = filterModel.errorCategory?.type;
      errorCode = errorCode === 'Stat Code(s)' ? 'Stat' : errorCode;
      errorCode === 'All' ? gridRef.current?.api.setQuickFilter('') : gridRef.current?.api.setQuickFilter(errorCode);
    }

    if (filterModel?.orderDate) {
      setDatesFromFilterModel(filterModel?.orderDate);
    }
    if (gridRef.current?.api?.getDisplayedRowCount() === 0) {
      gridRef.current?.api.showNoRowsOverlay();
    } else {
      gridRef.current?.api.hideOverlay();
    }
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const externalFilterChanged = useCallback((newValue: string, isQueueFilter = false) => {
    if (!isQueueFilter) {
      setCplFilter(newValue);
      externalCplFilter = newValue;
    } else {
      setQueueFilter(newValue);
      externalQueueFilter = newValue;
    }
    gridRef.current?.api?.onFilterChanged();
  }, []);

  const isExternalFilterPresent = useCallback((): boolean => {
    return tabName === report || externalCplFilter !== 'allFiles' || tabName === pay;
  }, []);

  const doesExternalFilterPassReview = useCallback(
    (node: any): boolean => {
      if (node.data) {
        switch (externalCplFilter) {
          case 'cplOnlyFiles':
            return node.data.isCplOnly;
          case 'excludeCplOnlyFiles':
            return !node.data.isCplOnly;
          default:
            return true;
        }
      }
      return true;
    },
    [externalCplFilter],
  );

  const doesExternalFilterPassReport = useCallback(
    (node: any): boolean => {
      if (node.data) {
        let isCplFilterPassed = true;
        let isQueueFilterPassed = true;

        if (externalCplFilter === 'cplOnlyFiles') {
          isCplFilterPassed = node.data.isCplOnly;
        } else if (externalCplFilter === 'excludeCplOnlyFiles') {
          isCplFilterPassed = !node.data.isCplOnly;
        }

        if (externalQueueFilter === 'firmQueueOnlyFiles') {
          isQueueFilterPassed = node.data.isFirmQueue;
        } else if (externalQueueFilter === 'myQueueOnlyFiles') {
          isQueueFilterPassed = node.data.isMyQueue;
        } else if (externalQueueFilter === 'eligibleFiles') {
          isQueueFilterPassed = !node.data.isMyQueue && !node.data.isFirmQueue;
        }
        return isCplFilterPassed && isQueueFilterPassed;
      }
      return true;
    },
    [externalCplFilter, externalQueueFilter],
  );

  const doesExternalFilterPassPay = useCallback(
    (node: any): boolean => {
      const textLength = searchText.length;
      if (textLength >= 3 && textLength <= 100) {
        return (
          node?.data?.fileNumbers?.some((fileNum: string) =>
            fileNum?.toLowerCase()?.includes(searchText?.toLowerCase()),
          ) ||
          node?.data?.policyNumbers?.some((policyNum: string) =>
            policyNum?.toLowerCase()?.includes(searchText?.toLowerCase()),
          )
        );
      }
      return true;
    },
    [searchText],
  );

  const CustomOverlayNoRows = () => {
    return (
      <div className={contentClassName}>
        <NoResults
          showButton={false}
          type="results"
          titleText="No Results Found"
          pText="Try adjusting your filters to find what you’re looking for."
        />
      </div>
    );
  };

  const CustomOverlayLoading = () => {
    return (
      <div className="files-container">
        <div className="no-rows">
          <LoadingSpinner status="pending" className="files-container--spinner-root" />
        </div>
      </div>
    );
  };

  const tabText: any = {
    review: `Selected ${selectedRows?.length} Files to export`,
    report: `Selecting ${selectedRows?.length} file(s) for ${numberValueFormatter(sumWithReduce, true)}`,
    pay: `Selecting ${selectedRows?.length} order(s) for ${numberValueFormatter(sumWithReduce, true)}`,
  };

  const selectedFilesText: string = tabText[action];

  const handleCloseQueueDialog = () => {
    if (executeQueueDataStatus === 'pending') return;
    setOpenQueueModal(false);
  };

  const handleUnableToProcessDialog = () => {
    setOpenUnableToProcessModal(false);
  };

  const handleCloseReportDialog = () => {
    setOpenReportModal(false);
    setReportCLicked(false);
    setShowValidationError(false);
    setNotifyEmail(profile?.emailAddress ?? '');
  };

  const handlePrimaryAction = () => {
    if (tabName === review) {
      executeExportData().then();
    } else if (tabName === report) {
      if (isReopened) {
        setOpenUnableToProcessModal(true);
      } else {
        setOpenReportModal(true);
      }
    } else {
      if (isReopened) {
        setOpenUnableToProcessModal(true);
      } else if (isAllOpenStatus && isAllunderwritersSame) {
        history.push({
          pathname: `/accounting/remittance/pay/invoice-summary`,
          state: {
            selectedRows,
          },
        });
      } else {
        setShowOnlyOpenStatusMsg(true);
      }
    }
  };

  const goBackAction = () => {
    // handleTabChange(false, 2);
    setReportFirmId(null);
    setReset(false);
    setSelectedRows([]);
    setReportFileOrderIds([]);
    setOpenInvoiceSummary(false);
    history.replace('/accounting/remittance/pay');
    executePayOrdersWithRange().then();
  };

  const filterOnSearch = (e: any) => {
    setSearchText(e?.target?.value ?? '');
    setShowValidationError(true);
  };

  useLayoutEffect(() => {
    if (gridRef?.current?.api && payGridReady) {
      gridRef?.current?.api?.setFilterModel?.({
        orderStatus: {
          values: ['Manual Pay in Process', 'Open', 'Wire in Process', 'In Process', 'ePay In Process'],
          filterType: 'set',
        },
      });
      setPayGridReady(false);
    }
  }, [gridRef?.current?.api, payGridReady]);

  const onGridReady = (params: any) => {
    if (gridReadyTimeout?.current) {
      clearTimeout(gridReadyTimeout.current);
    }
    // apply filter after a delay
    gridReadyTimeout.current = setTimeout(() => {
      onGridReadyFilter(params);
      clearTimeout(gridReadyTimeout.current);
    }, 0);
  };
  const onGridReadyFilter = (params: any) => {
    if (filterModel.current) {
      if (tabName === pay && Object.keys(filterModel.current).length === 0) {
        setPayGridReady(true);
      } else {
        params?.api?.setFilterModel?.(filterModel.current);
      }
      filterModel.current = {};
    }

    if (sortModel.current) {
      params?.columnApi?.applyColumnState?.({
        state: sortModel.current,
        applyOrder: true,
      });
      sortModel.current = {};
    }

    if (selectedRows?.length > 0 && tabName !== pay) {
      const tempSelectedRows: any[] = [];
      const selectedRowIds = selectedRows.map((row: any) => row.fileId);
      const filterCallback = tabName === report ? doesExternalFilterPassReport : doesExternalFilterPassReview;
      params?.api?.forEachNode((node: any) => {
        if (selectedRowIds.includes(node.data.fileId) && filterCallback(node)) {
          node.setSelected(true);
          tempSelectedRows.push(node.data);
        }
      });
      setSelectedRows(tempSelectedRows);
    }
    setDisplayedRowCount(params?.api?.getDisplayedRowCount());
  };

  useEffect(() => {
    if (reportListStatus === 'pending' || payOrdersWithRangeStatus === 'pending') {
      filterModel.current = gridRef?.current?.api?.getFilterModel?.() ?? {};
      sortModel.current = gridRef?.current?.columnApi?.getColumnState?.() ?? {};
      setGridLoader(true);
      setDisplayedRowCount(0);
    } else {
      setGridLoader(false);
    }
  }, [reportListStatus, payOrdersWithRangeStatus]);

  const tabSubtitle = {
    Review:
      'This tab shows files currently marked as "Open" but cannot be reported due to outstanding issues. To get these files ready for reporting and payment, use the "Review" tab to identify and fix the problems.',
    Report:
      'This tab shows all files that meet the requirements for reporting to First American. Use the “Report” tab to select individual files or groups of files to Queue, Report, or Report & Pay.',
    Pay: 'This tab shows all “Open” Invoices that are ready for payment. Use the "Pay" tab to select individual Invoices or groups of Invoices and choose your preferred payment method: electronic payment (ePay), wire transfer, or manual payment.',
  };

  const notifyReporting: React.ReactNode = (
    <>
      <Typography>{tabText[1]}</Typography>
      &nbsp;&nbsp;
      <Typography>
        We will notify you at the email address below when your orders are available for you to pay.
      </Typography>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <AgentNetTextInput
        variant="outlined"
        label="Email"
        name="notifyEmail"
        value={notifyEmail}
        onChange={(e: any) => {
          setNotifyEmail(e.target.value);
        }}
        fullWidth
        helperText={
          (validationErrors ?? [])?.some((err) => err.field === 'notifyEmail') && showValidationError
            ? null
            : 'To add multiple email addresses, please separate email addresses with a semi-colon.'
        }
        disabled={false}
        showValidation={showValidationError}
        errs={validationErrors}
        required
      />
      &nbsp;&nbsp;
      {isNYStateFile ? (
        <Typography>
          I, {profile?.firstName} {profile?.lastName}, of {userFirm?.name} hereby certify that the Title Premium(s) were
          properly calculated and found to be accurate. I further certify that only permitted, proper fees and other
          charges have been made. I further certify that I have provided clear notice to the insured and/or borrower, as
          required, that the lender or other party may require the performance of additional services which are not
          necessary services in connection with the issuance of the title policy, and the issuance of the title policy
          is not dependent upon the performance of such additional services.
        </Typography>
      ) : null}
    </>
  );

  if (showGridLoader) {
    return <> {CustomOverlayLoading()}</>;
  }

  if (openInvoiceSummary) {
    return (
      <InvoiceSummary
        payFiles={payFiles}
        goBackAction={goBackAction}
        selectedRows={selectedRows}
        isAllOpenStatus={isAllOpenStatus}
      />
    );
  }

  if (!rowData || rowData.length === 0) {
    const pText =
      tabName === review ? noFilesText['review'] : tabName === report ? noFilesText['report'] : noFilesText['pay'];
    return (
      <div className={contentClassName}>
        <NoResults showButton={false} type="files" titleText={`No Files to ${tabName}`} pText={pText} />
      </div>
    );
  } else {
    return (
      <>
        <AgentNetDivider
          typoVariant="h1"
          title={`${tabName} Files`}
          subtitleMuted
          subtitle={tabSubtitle[tabName as 'Review' | 'Report' | 'Pay']}
        />

        <Grid container justifyContent="space-between" className={classes.paddingX}>
          <Grid item sm={2}>
            {tabName === pay ? (
              <Grid
                style={{
                  display: 'flex',
                }}
              >
                <SearchField
                  onChange={filterOnSearch}
                  value={searchText}
                  onSearch={() => setShowValidationError(true)}
                  name="searchText"
                  errs={validationErrors}
                  executeOnSearchOnChange={false}
                  displayError={showValidationError}
                  qaAttribute="PayFilesSearchInput"
                  showCLoseIcon={true}
                  onClose={() => {
                    setSearchText('');
                    setShowValidationError(false);
                  }}
                  placeholder="Search File or Policy Number"
                />
                <InfoIcon
                  title={searchFilesReportedText}
                  size="large"
                  placement="right"
                  arrow={true}
                  style={{ position: 'relative', top: '5px', cursor: 'pointer' }}
                />
              </Grid>
            ) : (
              <AgentNetDropdownSelector
                name="reviewFilter"
                Data-QA={`${tabName}Filter`}
                options={cplFilters ?? []}
                value={cplFilter}
                id="cplFilter"
                dropdowntype="outlined"
                menuOption={(value: any) => {
                  externalFilterChanged(value);
                  return false;
                }}
                compact
              />
            )}
          </Grid>
          {tabName === review ? (
            displayFileReopenedIcon ? (
              <ReopenedFileIcon></ReopenedFileIcon>
            ) : null
          ) : tabName === report ? (
            <>
              <Grid item style={{ margin: '0 0 1.6rem 2.4rem', display: 'flex' }}>
                {displayFileReopenedIcon ? <ReopenedFileIcon></ReopenedFileIcon> : null}
                <ButtonGroup>
                  {queueFilters.map((obj) => (
                    // eslint-disable-next-line react/jsx-key
                    <AgentNetButton
                      variant={queueFilter === obj?.value ? 'contained' : 'outlined'}
                      onClick={() => {
                        resetFiltersAndSelection(obj?.value, cplFilter);
                      }}
                      data-qa={reportQueueButtonAttributes[obj?.value]}
                    >
                      {`${obj?.name} (${queueFilesCount[obj?.value]})`}
                    </AgentNetButton>
                  ))}
                </ButtonGroup>
              </Grid>
            </>
          ) : tabName === pay ? (
            <>
              <Grid
                item
                style={{
                  margin: '0 2.4rem',
                  display: 'flex',
                  fontSize: '14px',
                  alignItems: 'center',
                  width: '375px',
                  justifyContent: 'space-evenly',
                }}
              >
                {displayFileReopenedIcon ? <ReopenedFileIcon></ReopenedFileIcon> : null}
                <AutoReportingIcon fontSize="small" /> Auto-Reporting Order <InfoIcon title={autoReportingInfoText} />
              </Grid>
            </>
          ) : null}
        </Grid>
        <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={onFilterChanged}
            animateRows={true}
            onGridReady={onGridReady}
            {...(tabName === 'Report' && { isRowSelectable })}
            isExternalFilterPresent={isExternalFilterPresent as any}
            doesExternalFilterPass={
              tabName === report
                ? doesExternalFilterPassReport
                : tabName === pay
                ? doesExternalFilterPassPay
                : doesExternalFilterPassReview
            }
            components={{
              customDropdownFilter: FloatingFilterComponent,
              customOverlayNoRows: CustomOverlayNoRows,
            }}
            noRowsOverlayComponent={'customOverlayNoRows'}
          ></AgGridReact>
        </div>
        <div
          className={clsx('selected-rows', classes.paddingX, classes.selectedRows, {
            [classes.selectedRowsActive]: exportedFiles?.files?.length,
          })}
        >
          <span>
            Showing {displayedRowCount ?? getDisplayedRowCount()} of {totalFileCount}{' '}
            {tabName === 'Pay' ? 'orders' : 'files'}
          </span>
          {!selectedRows?.length && tabName === review ? null : selectedFilesText}
        </div>
        {selectedRows?.length ? (
          <BottomAppBar
            cancelLabel="Clear All"
            onCancel={() => resetFiltersAndSelection()}
            cancelDataQa={`${tabName}ClearAll`}
            showPrimary={tabName === review || hasPayRight}
            disablePrimary={tabName === report && (selectedRows.length >= reportAndPayLimit || !isFilesReportable)}
            disabledPrimaryHoverText="You need to report your selected files before you can pay. Once the orders are processed you will be able to pay via the Pay tab."
            primaryActionLabel={
              tabName === review ? 'Export' : tabName === report ? 'Report & Pay' : 'View Invoice & Pay'
            }
            onPrimaryClick={handlePrimaryAction}
            primaryActionDataQa={
              tabName === review ? `${tabName}Export` : tabName === report ? 'ReportReport&Pay' : 'ViewInvoice&Pay'
            }
            secondaryActionLabel={tabName === review ? '' : tabName === report ? 'Report' : 'Export'}
            onSecondaryClick={
              tabName === report
                ? () => {
                    setReportCLicked(true);
                    setOpenReportModal(true);
                  }
                : () => executeExportData().then()
            }
            secondaryActionDataQa={tabName === review ? '' : tabName === report ? 'ReportReport' : 'PayExport'}
            thirdActionLabel={
              tabName === report
                ? queueFilter === 'firmQueueOnlyFiles' || queueFilter === 'myQueueOnlyFiles'
                  ? 'Remove from Queue'
                  : 'Queue'
                : ''
            }
            thirdActionDataQa={
              tabName === report
                ? queueFilter === 'firmQueueOnlyFiles' || queueFilter === 'myQueueOnlyFiles'
                  ? 'ReportRemoveFromQueue'
                  : 'ReportQueue'
                : ''
            }
            onThirdClick={
              queueFilter === 'eligibleFiles' ? () => executeQueueData().then() : () => setOpenQueueModal(true)
            }
            fourthActionLabel={tabName === report ? 'Export' : ''}
            onFourthClick={() => executeExportData().then()}
            fourthActionDataQa={`${tabName}Export`}
          />
        ) : null}

        {tabName === pay ? (
          <PayDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            drawerData={drawerData}
            setDrawerData={setDrawerData}
            setSelectedRows={setSelectedRows}
            hasPayRight={hasPayRight}
          />
        ) : (
          <ReportsAndPayDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            drawerData={drawerData}
            setDrawerData={setDrawerData}
            getUpdatedData={getUpdatedData}
            // handleTabChange={handleTabChange}
            rateAndFeeExecute={rateAndFeeExecute}
            hasViewPayRight={hasViewPayRight}
          />
        )}
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationQueue"
          disabled={executeQueueDataStatus === 'pending'}
          onConfirm={() => executeQueueData().then()}
          open={openQueueModal}
          onDismissAction={handleCloseQueueDialog}
          dialogTitle="Remove From Queue"
          dialogBtnContent="Yes, Continue"
          dialogText="Removing file(s) from Queue.  Do you want to continue?"
        />
        <AgentNetConfirmationDialog
          qaAttrPrefix="UnableToProcessRequestReopen"
          onConfirm={() => handleUnableToProcessDialog()}
          open={openUnableToProcessModal}
          onDismissAction={handleUnableToProcessDialog}
          dialogTitle="Unable to Process Request"
          dialogTextHTML={
            <>
              <Typography variant="body1">
                <p className={classes.mt0}>
                  Resubmitted files will not be eligible for payment until an invoice is generated, to ensure proper
                  credits are applied.
                </p>
                <p className={classes.mb0}> Please uncheck any files marked as reopened and try again.</p>
              </Typography>
            </>
          }
          dialogBtnContent="Got it!"
          dialogActionBtnJustifyContent="flex-end"
          showSecondaryActionBtn={false}
        />
        <AgentNetConfirmationDialog
          qaAttrPrefix={isFilesReportable || reportCLicked ? 'ReportData' : 'ReportUnableProcessRequest'}
          disabled={executeReportDataStatus === 'pending'}
          onConfirm={() => {
            if (validationErrors.some((err) => err.field === 'notifyEmail')) {
              setShowValidationError(true);
            } else {
              (isFilesReportable && selectedRows.length <= reportAndPayLimit) || reportCLicked
                ? executeReportData().then()
                : handleCloseReportDialog();
            }
          }}
          open={openReportModal}
          onDismissAction={handleCloseReportDialog}
          dialogTitle={
            selectedRows.length > reportAndPayLimit && reportCLicked
              ? 'Get Notified'
              : (isFilesReportable && selectedRows.length <= reportAndPayLimit) || reportCLicked
              ? isNYStateFile
                ? 'NY Certification'
                : 'Reporting file(s)'
              : 'Unable to Process Request'
          }
          dialogBtnContent={
            selectedRows.length > reportAndPayLimit && reportCLicked
              ? 'Notify Me'
              : (isFilesReportable && selectedRows.length <= reportAndPayLimit) || reportCLicked
              ? 'Yes, Continue'
              : 'Got it!'
          }
          dialogText={
            !reportCLicked && selectedRows.length > reportAndPayLimit
              ? restrictLimitReportingText
              : isFilesReportable || reportCLicked
              ? isNYStateFile
                ? `I, ${profile?.firstName} ${profile?.lastName}, of ${userFirm?.name} hereby certify that the Title Premium(s) were properly calculated and found to be accurate. I further certify that only permitted, proper fees and other charges have been made. I further certify that I have provided clear notice to the insured and/or borrower, as required, that the lender or other party may require the performance of additional services which are not necessary services in connection with the issuance of the title policy, and the issuance of the title policy is not dependent upon the performance of such additional services.`
                : `Reporting ${selectedRows?.length} file(s) for ${numberValueFormatter(
                    sumWithReduce,
                    true,
                  )}. Do you want to continue?`
              : restrictReportingText
          }
          dialogTextHTML={
            !reportCLicked || (selectedRows.length <= reportAndPayLimit && reportCLicked) ? null : notifyReporting
          }
        />
      </>
    );
  }
};

export default React.memo(ReportsList);
