export interface FileOrderInsightsType {
  fileId: string;
  fileNumber: string;
  isGenerating: boolean;
  lastGeneratedDate?: string;
  hasError: boolean;
  parties: Party[];
  properties: Property[];
  jackets: OrderInsightJacket[];
}
export interface Party {
  id: number;
  partyType: string;
  filePartyType: 'Buyer' | 'Seller' | 'Lender';
  partyName: string;
  isSpouse: boolean;
  address: Address;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  risks?: Risk[];
  expanded?: boolean;
}

export class Address {
  isForeignAddress?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
  county?: string;
  country?: string;
}

export interface Property {
  propertyId: number;
  isDefaultProperty?: boolean;
  propertyType: string;
  address: Address;
  apn: string;
  legalDescription: string;
  longLegalDesc: string;
  legalSection: string;
  legalDistrict: string;
  alternateAPN: string;
  taxAccountNumber: string;
  censusTractBlock: string;
  legalBlock: string;
  legalLot: string;
  townshipRangeSection: string;
  legalBookPage: string;
  mapReference: string;
  subdivision: string;
  schoolDistrict: string;
  municipalityTownship: string;
  neighborhoodName: string;
  situsCarrierRoute: string;
  taxInformation: TaxInformation;
  parsedStreetAddress: ParsedStreetAddress;
  ownerInformation: OwnerInformation;
  tract: string;
  marketArea: string;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  landUseDescription?: string;
  latitude?: number;
  longitude?: number;
  propertyDetailId?: number;
  combinedEstimatedLoanBalance?: number;
  risks?: Risk[];
  onFileOwnerNames?: string[];
  onFileOwnersMailingAddresses?: Address[];
  expanded?: boolean;
}

export interface Risk {
  category: number;
  categoryName: string;
  type: number;
  typeName: string;
  severity: number;
  severityName: 'Alert' | 'Warning' | 'None' | 'Success';
  title: string;
  description: string;
}

export interface TaxInformation {
  taxYear?: number;
  propertyTax?: number;
  taxArea?: string;
  taxExemption?: string;
  assessedYear?: number;
  assessedValue?: number;
  landValue?: number;
  improvementValue?: number;
  improvedPercent?: number;
  marketValue?: number;
  marketLandValue?: number;
  marketImprovValue?: number;
  marketImprovValuePercent?: number;
}

export interface ParsedStreetAddress {
  standardizedHouseNumber?: number;
  standardizedHouseNumberString?: string;
  directionPrefix?: string;
  streetName?: string;
  streetSuffix?: string;
  directionSuffix?: string;
}

export interface OwnerInformation {
  ownerNames?: string;
  ownerOccupiedIndicator?: string;
  vestingOwner?: string;
  vestingOwnershipRight?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip9?: string;
  mailCarrierRoute?: string;
}
export interface OrderInsightJacket {
  risks: Risk[];
  authLimit: number;
  isMaxLiabilityAmount: boolean;
  fileServiceJacketId: number;
  policyNumber: string;
  policyType: string;
  liabilityAmount: number;
  policyEffectiveDate: string;
  policyStatus: string;
}

export enum RiskType {
  AuthLimit,
  ClaimParty,
  ClaimProperty,
  FinCEN,
  Foreign,
  LowAssess,
  LowMarket,
  MultipleProperties,
  NonOwner,
  OwnerName,
  OwnerAddr,
  SDN,
  Vacant,
  WatchListParty,
  WatchListProperty,
  MissingData,
  Unknown,
}

export const severityOrder = {
  Alert: 1,
  Warning: 2,
  None: 3,
  Success: 4,
};

export const filePartyTypeMap = {
  Buyer: 'Buyer/Borrower',
  Seller: 'Seller/Owner',
  Lender: 'Lender',
};

export type PropertyOrderInsightsProps = {
  property: Property;
  isPdf: boolean;
  index?: number;
  policyLiabilityJacket?: OrderInsightJacket;
};

export type PartyOrderInsightsProps = {
  party: Party;
  isPdf: boolean;
};

export enum RiskCategory {
  AssessedValue,
  ClaimAlertParty,
  FinCen,
  MarketValue,
  MortgageBalance,
  Occupancy,
  OwnerAddr,
  OwnerName,
  PolicyLiability,
  PropertyInfo,
  SDN,
  WatchListParty,
  Unknown,
  Property = 'Property',
  Party = 'Party',
}

export const OrderInsightsError: FileOrderInsightsType = {
  fileId: '',
  fileNumber: '',
  isGenerating: false,
  hasError: true,
  parties: [],
  properties: [],
  jackets: [],
};

export const categoryToTypesMap: { [key in RiskCategory]: number[] } = {
  [RiskCategory.AssessedValue]: [RiskType.LowAssess, RiskType.MultipleProperties, RiskType.MissingData],
  [RiskCategory.ClaimAlertParty]: [RiskType.ClaimParty, RiskType.MissingData],
  [RiskCategory.FinCen]: [RiskType.FinCEN, RiskType.MissingData],
  [RiskCategory.MarketValue]: [RiskType.LowMarket, RiskType.MultipleProperties, RiskType.MissingData],
  [RiskCategory.MortgageBalance]: [RiskType.MissingData],
  [RiskCategory.Occupancy]: [RiskType.NonOwner, RiskType.MissingData],
  [RiskCategory.OwnerAddr]: [RiskType.OwnerAddr, RiskType.Foreign, RiskType.MissingData],
  [RiskCategory.OwnerName]: [RiskType.OwnerName, RiskType.MissingData],
  [RiskCategory.PropertyInfo]: [
    RiskType.WatchListProperty,
    RiskType.ClaimProperty,
    RiskType.Vacant,
    RiskType.MissingData,
  ],
  [RiskCategory.SDN]: [RiskType.SDN, RiskType.MissingData],
  [RiskCategory.WatchListParty]: [RiskType.WatchListParty, RiskType.MissingData],
  [RiskCategory.Property]: [RiskType.MissingData],
  [RiskCategory.Party]: [],
  [RiskCategory.PolicyLiability]: [RiskType.AuthLimit, RiskType.MissingData],
  [RiskCategory.Unknown]: [RiskType.MissingData],
};

export function getParentSeverity(risks: Risk[] | undefined, categoryName: RiskCategory): string {
  if (!risks || risks.length === 0) {
    return 'Success';
  }

  const isPropertyOrParty = categoryName === RiskCategory.Property || categoryName === RiskCategory.Party;
  const types = categoryToTypesMap[categoryName];

  const filteredRisks = isPropertyOrParty
    ? risks.filter((x) => x.categoryName !== RiskCategory[RiskCategory.Unknown])
    : risks.filter(
        (x) =>
          x.categoryName === RiskCategory[categoryName] &&
          types.includes(RiskType[x.typeName as keyof typeof RiskType]),
      );

  const sortedRisks =
    filteredRisks.length !== 1
      ? filteredRisks.sort((a, b) => severityOrder[a?.severityName] - severityOrder[b?.severityName])
      : filteredRisks;
  return sortedRisks[0]?.severityName ?? 'Success';
}

export function capitalizeFirstLetter(str: string): string {
  const words = str.split(' ');
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export function categorizeOrderInsightRisks(
  risks: Risk[] = [],
  categoryName: RiskCategory,
): { alertRisks: Risk[]; warningRisks: Risk[]; unknownRisks: Risk[] } {
  const alertRisks: Risk[] = [];
  const warningRisks: Risk[] = [];
  const unknownRisks: Risk[] = [];

  const filteredRisks = risks.filter((x) => x.categoryName === RiskCategory[categoryName]);

  filteredRisks?.forEach((risk) => {
    switch (risk.severityName) {
      case 'Alert':
        alertRisks.push(risk);
        break;
      case 'Warning':
        warningRisks.push(risk);
        break;
      case 'None':
        unknownRisks.push(risk);
        break;
    }
  });

  return { alertRisks, warningRisks, unknownRisks };
}

export function toFullAddress(addr: Address): string {
  const addressParts = [];
  if (addr.address1) {
    addressParts.push(addr.address1);
  }
  if (addr.address2) {
    addressParts.push(' ' + addr.address2);
  }
  if (addr.city) {
    addressParts.push(', ' + addr.city);
  }
  if (addr.stateOrProvince) {
    addressParts.push(', ' + addr.stateOrProvince);
  }
  if (addr.postalCode) {
    addressParts.push(' ' + addr.postalCode);
  }
  if (addr.country) {
    addressParts.push(', ' + addr.country);
  }
  return addressParts.join('');
}

export function extractRisks(entities: (Party | Property)[], severities: string[]): Risk[] {
  return entities.reduce((acc: Risk[], entity: Party | Property) => {
    if (entity.risks) {
      acc = acc.concat(entity.risks.filter((risk: Risk) => severities.some((x) => x === risk.severityName)));
    }
    return acc;
  }, []);
}

export const disclaimerText =
  "First American Order Insights is a service offered exclusively to First American’s policy issuing agents for the purpose of aiding in the preparation of title policies underwritten by First American Title Insurance Company and its affiliated insurer entities. This service utilizes agent-provided data, alongside First American's internal data and data from third-party resources, to identify potential matters of concern or property record mismatches signaling the necessity for further investigation and security measures. Please be aware that the data presented in Order Insights is purely informational and requires verification by the issuing Agent, as no assurance can be given regarding the accuracy of the internal data or third-party information provided through Order Insights. Additionally, it is important to recognize that many of the parties and properties reported may be victims of identity theft or other forms of fraud. The inclusion of persons or properties in Order Insight lists is not an indication that the person or property was involved in disreputable activities but serves as a warning that extra vigilance is essential to ensure the protection of all transaction participants.";
