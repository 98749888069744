import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import React from 'react';
import { Jacket } from 'api/file/interfaces/get-file-summary-report';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

type JacketInfoProps = {
  jackets: Jacket[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
      boxShadow: 'none',
    },
    tableHead: {
      padding: theme.spacing(1, 1, 0, 1),
      fontSize: '11pt',
      fontWeight: 800,
      border: 'none',
    },
    tableCell: {
      padding: theme.spacing(1, 1, 0, 1),
      fontSize: '10pt',
    },
  }),
);

const JacketInfo: React.FC<JacketInfoProps> = ({ jackets }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} align="left">
              Policy No.
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Policy Type
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Policy Amount
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Date of Policy
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jackets.map((row: Jacket, index: number) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" className={classes.tableCell} align="left">
                {row.policyNumber}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.policyType}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {(row.liabilityAmount ?? -1) >= 0 && (
                  <>
                    {Number(row.liabilityAmount).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </>
                )}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.policyEffectiveDate}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.policyStatus}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default JacketInfo;
